import React from "react";
import Markdoc from "@markdoc/markdoc";
import "./openApi.styles.css";

class OpenApiHelpers {
  private _schema: any;

  constructor(schema: any) {
    this._schema = schema;
  }

  public isRef(node: any): boolean {
    return !!node["$ref"];
  }

  public resolveRef(node: any): any {
    return !this.isRef(node)
      ? node
      : node["$ref"]
          .split("/")
          .splice(1)
          .reduce(
            (obj: any, part: string) => (obj ? obj[part] : obj),
            this._schema,
          );
  }

  public getRefTypeName(node: any): string | undefined {
    return this.isRef(node) ? node["$ref"].split("/").pop() : undefined;
  }

  public getTypeName(node: any, includeSuffix = false): string {
    let suffix = "";

    if (this.isRef(node)) {
      const resolvedNode = this.resolveRef(node);

      if (!!resolvedNode["x-docs-type"]) return resolvedNode["x-docs-type"];

      if (resolvedNode.anyOf)
        suffix +=
          " (Any of: " + this.getTypeNames(resolvedNode).join(", ") + ")";
      else if (resolvedNode.oneOf)
        suffix +=
          " (One of: " + this.getTypeNames(resolvedNode).join(", ") + ")";
      else suffix += " (" + this.getTypeName(resolvedNode) + ")";

      return (this.getRefTypeName(node) + (includeSuffix ? suffix : "")).trim();
    }

    if (!!node["x-docs-type"]) return node["x-docs-type"];

    if (node.anyOf) return this.getTypeNames(node).join(", ");

    if (node.oneOf) return this.getTypeNames(node).join(" | ");

    if (this.isEnum(node)) suffix += " (enum)";

    if (this.hasFormat(node)) suffix += ` (${node.format})`;

    if (node.type === "array") {
      let itemType;

      if (this.isRef(node.items))
        itemType =
          this.resolveRef(node.items)["x-docs-type"] ??
          this.getRefTypeName(node.items);
      else if (!!node.items.anyOf || !!node.items.oneOf) itemType = "items";
      else itemType = this.getTypeName(node.items);

      return `${itemType}[]`;
    }

    return (node.type + (includeSuffix ? suffix : "")).trim();
  }

  public getTypeNames(node: any): string[] {
    if (node.anyOf)
      return node.anyOf.flatMap((childNode: any) =>
        this.getTypeNames(childNode),
      );

    if (node.oneOf)
      return node.oneOf.flatMap((childNode: any) =>
        this.getTypeNames(childNode),
      );

    return [this.getTypeName(node)];
  }

  public isEnum(node: any): boolean {
    const enumValues = this.getEnumValues(node);
    return !!enumValues && enumValues.length > 0;
  }

  public getEnumValues(node: any): any[] | undefined {
    const allValues = this.resolveRef(node).enum;
    if (allValues) {
      // remove case-insensitive duplicates, keep the original if not duplications
      const map = new Map<string, string>();
      for (const item of allValues) {
        const lowerCaseItem = item.toLowerCase();
        if (!map.has(lowerCaseItem) || item === item.toUpperCase()) {
          map.set(lowerCaseItem, item);
        }
      }
      return Array.from(map.values());
    }
    return allValues;
  }

  public hasFormat(node: any): boolean {
    return !!this.getFormat(node);
  }

  public getFormat(node: any): string | undefined {
    return this.resolveRef(node).format;
  }

  public isRequired(schema: any, name: string) {
    return schema.required?.indexOf(name) != -1;
  }

  public isNullable(node: any) {
    return !!this.resolveRef(node).nullable;
  }

  public isSimpleType(node: any) {
    const resolvedNode = this.resolveRef(node);

    return (
      resolvedNode["x-docs-force-simple-type"] ||
      ((!resolvedNode.anyOf ||
        (!!resolvedNode.anyOf &&
          resolvedNode.anyOf.filter((s: any) => this.isVisible(s)).length ===
            0)) &&
        (!resolvedNode.oneOf ||
          (!!resolvedNode.oneOf &&
            resolvedNode.oneOf.filter((s: any) => this.isVisible(s)).length ===
              0)) &&
        (resolvedNode.type !== "array" ||
          (resolvedNode.type === "array" &&
            !this.isVisible(resolvedNode.items))) &&
        (resolvedNode.type !== "object" ||
          (resolvedNode.type === "object" &&
            Object.keys(resolvedNode.properties).filter((s: any) =>
              this.isVisible(resolvedNode.properties[s]),
            ).length === 0)))
    );
  }

  public isVisible(node: any) {
    const resolvedNode = this.resolveRef(node);
    return !resolvedNode["x-docs-hide"];
  }
}

const OpenApiSpecContext = React.createContext(null);

const Markdown = ({ body }: { body: string }) => {
  const ast = Markdoc.parse(body);
  const content = Markdoc.transform(ast, {
    nodes: {
      document: {
        render: undefined,
      },
    },
  });
  const html = Markdoc.renderers.html(content);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const EnumValues = ({
  values,
  preview,
}: {
  values: string[];
  preview: number;
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const showValues = (isExpanded ? values : values.slice(0, preview)).map(
    (val, idx, arr) => (
      <span key={idx}>
        <code>{val}</code>
        {idx + 1 < arr.length && ", "}
      </span>
    ),
  );

  if (values.length <= preview) return showValues;

  const hiddenValuesCount = values.length - preview;

  return (
    <span>
      {showValues}
      {isExpanded && (
        <>
          &nbsp;
          <button
            className="toggle-button"
            onClick={() => setIsExpanded(false)}>
            show less
          </button>
        </>
      )}
      {!isExpanded && (
        <>
          &nbsp;
          <button className="toggle-button" onClick={() => setIsExpanded(true)}>
            ... show {hiddenValuesCount} more
          </button>
        </>
      )}
    </span>
  );
};

function InlineTabs({
  options,
}: {
  options: { name: string; content: string }[];
}) {
  const [selectedIdx, setSelectedIdx] = React.useState(0);
  return (
    <>
      <div className="inline-tab-container">
        {options.map((option, idx) => (
          <button
            className={`inline-tab-option ${
              idx === selectedIdx ? "active" : ""
            }`}
            key={idx}
            onClick={() => setSelectedIdx(idx)}>
            {option.name}
          </button>
        ))}
      </div>
      <div className="inline-tab-content">{options[selectedIdx].content}</div>
    </>
  );
}

function OpenApiDescription({
  schema,
  isRequired,
  name,
}: {
  schema: any;
  isRequired: boolean;
  name?: string;
}) {
  const spec = React.useContext(OpenApiSpecContext);
  const oa = new OpenApiHelpers(spec);
  const resolvedSchema = oa.isRef(schema) ? oa.resolveRef(schema) : schema;
  const typeName = oa.getTypeName(schema);
  const isEnum = oa.isEnum(schema);
  const enumValues = isEnum ? oa.getEnumValues(schema) : [];

  return (
    <>
      <div className="property-row">
        {name && <p>{name}</p>}
        {typeName.split(", ").map((part) => (
          <code key={part}>{part}</code>
        ))}
        <div>
          {isRequired && <div className="format-span">Required</div>}
          {oa.hasFormat(schema) && (
            <div className="format-span">
              {oa.getFormat(schema)!.replace("date-time", "Date")}
            </div>
          )}
          {!!resolvedSchema.pattern && (
            <div className="format-span">Pattern: {resolvedSchema.pattern}</div>
          )}
          {!!resolvedSchema.minimum && (
            <div className="format-span">Min: {resolvedSchema.minimum}</div>
          )}
          {!!resolvedSchema.maximum && (
            <div className="format-span">Max: {resolvedSchema.maximum}</div>
          )}
          {!!resolvedSchema.minLength && (
            <div className="format-span">
              Min length: {resolvedSchema.minLength}
            </div>
          )}
          {!!resolvedSchema.maxLength && (
            <div className="format-span">
              Max length: {resolvedSchema.maxLength}
            </div>
          )}
        </div>
      </div>
      {!!resolvedSchema.description && (
        <div className="description">
          <Markdown body={resolvedSchema.description} />
        </div>
      )}
      {isEnum && enumValues && (
        <div className="description">
          <p>Enum {<EnumValues values={enumValues} preview={5} />}</p>
        </div>
      )}
    </>
  );
}

const PropertyTableRow = ({
  name,
  schema,
  isRequired,
}: {
  name: string;
  schema: any;
  isRequired: boolean;
}) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const spec = React.useContext(OpenApiSpecContext);
  const oa = new OpenApiHelpers(spec);
  const resolvedSchema = oa.isRef(schema) ? oa.resolveRef(schema) : schema;

  const isSimpleType = oa.isSimpleType(schema);
  return (
    <div className="property-table-row">
      <OpenApiDescription name={name} schema={schema} isRequired={isRequired} />
      {!isSimpleType && (
        <button
          className="clickable-expand"
          onClick={() => {
            setExpanded(!isExpanded);
          }}>
          {isExpanded ? "- Hide child parameters" : "+ Show child parameters"}
        </button>
      )}
      {!isSimpleType && isExpanded && (
        <div className="indented-div">
          <OpenApiSchema schema={resolvedSchema} />
        </div>
      )}
    </div>
  );
};

const OpenApiSchema = ({ schema }: { schema: any }) => {
  const spec = React.useContext(OpenApiSpecContext);
  const oa = new OpenApiHelpers(spec);
  const resolvedSchema = oa.isRef(schema) ? oa.resolveRef(schema) : schema;

  if (!oa.isVisible(resolvedSchema)) return null;

  if (!!resolvedSchema.anyOf) {
    if (resolvedSchema.anyOf.filter((s: any) => oa.isVisible(s)).length === 0)
      return null;

    return (
      <InlineTabs
        options={resolvedSchema.anyOf
          .filter((s: any) => oa.isVisible(s))
          .map((s: any) => ({
            name: oa.getTypeName(s),
            content: <OpenApiSchema schema={s} />,
          }))}
      />
    );
  }

  if (!!resolvedSchema.oneOf) {
    if (resolvedSchema.oneOf.filter((s: any) => oa.isVisible(s)).length === 0)
      return null;

    return (
      <InlineTabs
        options={resolvedSchema.oneOf
          .filter((s: any) => oa.isVisible(s))
          .map((s: any) => ({
            name: oa.getTypeName(s),
            content: <OpenApiSchema schema={s} />,
          }))}
      />
    );
  }

  switch (resolvedSchema.type) {
    case "array":
      if (!oa.isVisible(resolvedSchema.items)) return <code>Array</code>;

      return <OpenApiSchema schema={resolvedSchema.items} />;

    case "object":
      if (
        Object.keys(resolvedSchema.properties).filter((propName) =>
          oa.isVisible(resolvedSchema.properties[propName]),
        ).length === 0
      )
        return (
          <>
            {
              <OpenApiDescription
                schema={schema}
                isRequired={!!schema.required}
              />
            }
          </>
        );

      return (
        <div>
          {Object.keys(resolvedSchema.properties)
            .filter((propName) =>
              oa.isVisible(resolvedSchema.properties[propName]),
            )
            .map((propName) => (
              <PropertyTableRow
                key={propName}
                name={propName}
                schema={resolvedSchema.properties[propName]}
                isRequired={
                  resolvedSchema.required &&
                  resolvedSchema.required.some((rp: any) => rp === propName)
                }
              />
            ))}
        </div>
      );

    default:
      return (
        <div className="property-table-row">
          <OpenApiDescription schema={schema} isRequired={!!schema.required} />
        </div>
      );
  }
};

export const OpenApiComponent = ({
  name,
  spec,
}: {
  name: string;
  spec: any;
}) => {
  const schema = spec.components.schemas[name];
  const oa = new OpenApiHelpers(spec);

  if (!schema) return <div>Could not resolve OpenAPI schema for {name}</div>;

  if (!oa.isVisible(schema)) return null;

  return (
    <OpenApiSpecContext.Provider value={spec}>
      <div className="open-api-schema">
        <OpenApiSchema schema={schema} />
      </div>
    </OpenApiSpecContext.Provider>
  );
};

export const OpenApiPath = ({
  method,
  path,
  spec,
}: {
  method: string;
  path: string;
  spec: any;
}) => {
  const schema =
    spec.paths[path] && spec.paths[path][method.toLocaleLowerCase()];
  const oa = new OpenApiHelpers(spec);

  if (!schema)
    return (
      <div>
        Could not resolve OpenAPI schema for {method.toLocaleUpperCase()} {path}
      </div>
    );

  var urlParams: any[] = schema.parameters.filter((p: any) => p.in === "path");
  var queryParams: any[] = schema.parameters.filter(
    (p: any) => p.in === "query" && oa.isVisible(p.schema),
  );
  var reqBodySchema =
    schema.requestBody &&
    schema.requestBody.content &&
    schema.requestBody.content["application/json"].schema;

  return (
    <div className="open-api-schema">
      <OpenApiSpecContext.Provider value={spec}>
        {urlParams.length > 0 && (
          <div>
            <h4>URL Parameters</h4>
            {urlParams.map((param) => (
              <PropertyTableRow
                key={param.name}
                name={param.name}
                schema={param.schema}
                isRequired={!!param.required}
              />
            ))}
          </div>
        )}

        {queryParams.length > 0 && (
          <div>
            <h4>Query Parameters</h4>
            {queryParams.map((param) => (
              <PropertyTableRow
                key={param.name}
                name={param.name}
                schema={param.schema}
                isRequired={!!param.required}
              />
            ))}
          </div>
        )}

        {reqBodySchema && oa.isVisible(reqBodySchema) && (
          <div>
            <h4>Body Parameters</h4>
            <OpenApiSchema schema={reqBodySchema} />
          </div>
        )}
      </OpenApiSpecContext.Provider>
    </div>
  );
};
